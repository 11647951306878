import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

import Icons from '../../Icons';
import {downloadServiceUser, getSampleUserService} from '../../requests';
import {
    getCollectedFrom, getShareResult,
    getTechnology,
    getUserFacingSampleType, getYesNoDontKnowItem, removeUnderline,
    ucfirst
} from '../../helpers';
import moment from 'moment';
import UserServicePage from '../../Components/Page/userService';
import {StateContext} from "../../state";
import TermsOfService from "../ResultShareDetailViewShareDetailView/TermsOfService";
import ViewTechnology from "./ViewTechnology";
import './style.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

type IProps = {
    children?: any;
    onSample?: (smpl: any) => void;
    onReport?: (report: any) => void;
}

const UserServiceResultView: FC<IProps> = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [sample, setSample] = useState<any>({});
    const [reports, setReport] = useState<any>();
    const {sample_id} = useParams<any>();

    const {dispatch} = useContext(StateContext);

    const getSampleByID = useCallback(async (onDone?: any) => {

        let _sample: any;
        try {
            setError('');
            setLoading(true);
            const {data} = await getSampleUserService(sample_id);

            if (data.sample) {
                _sample = data.sample


                setSample(_sample);
                setReport(data.reports)
                props.onSample && props.onSample(_sample);
            } else {
                throw new Error(`Sample with id ${sample_id} not found`)
            }
        } catch (er: any) {
            setError(er.message);
        } finally {
            setLoading(false);
        }

        // eslint-disable-next-line
    }, [sample_id, props.onSample, props.onReport])

    useEffect(() => {
        getSampleByID();

        const getSampleEvent = (event: any) => getSampleByID(event.detail)
        window.addEventListener("getSample", getSampleEvent)
        return () => window.removeEventListener("getSample", getSampleEvent)
        // eslint-disable-next-line
    }, [])

    if (error) {
        return <div className="pal"><h2>Error: {error}</h2>
            <button className="btn btn2" onClick={() => getSampleByID()}>Try Again</button>
        </div>
    }

    if (loading) {
        return (<h2 className="pal">Loading...</h2>);
    }
    async function download(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        try {
            setLoading(true);
            const {data} = await downloadServiceUser(sample_id);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `results_${sample_id}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (err: any) {
            dispatch({
                type: "showAlert", payload: {
                    error: true,
                    message: err.message,
                }
            });
        } finally {
            setLoading(false);
        }
    }

    const header = <div className="sample-header">
        <div className="content">
            <div className="sample-id">Sample {sample_id}</div>
            {sample.report_state==="ANALYSIS_IMPOSSIBLE" || <div className="button">
                <button disabled={loading} onClick={download} className="btn btn1">
                    <Icons.Download
                        className="chevron"/><span className="hide-mobile">Download Results</span></button>
            </div>}
        </div>
    </div>

    const footer = (sample && !sample.survey_is_completed && sample.report_state!=='COULD_NOT_SHARE' && sample.report_state!=="ANALYSIS_IMPOSSIBLE") ?
        <div className="feedback">
            <div className="feedback-desc">
                {sample.report_state}
                <div className='pb-1'><b>Were these results helpful?</b></div>
                <div className="hide-mobile">Take our 1 minute survey, your feedback will help us improve our service.
                </div>
            </div>
            <div className="feedback-links">
                <div>
                    <Link className="thumb-up" to={'../share/' + sample_id}><Icons.ThumbUp/></Link>
                    <Link className="thumb-down" to={'../share/' + sample_id}><Icons.ThumbDown/></Link>
                </div>
            </div>

    </div>:<></>

    return (
        <UserServicePage footerClass={(sample && !sample.survey_is_completed) ? 'sample-footer' : ''} footer={footer}
                         mainClass="sample-result" showFooter={false} showSiteInfo={false} header={header}>

            <div className='result-page-content'>


                <div className='sample-view-content'>
                    <div className="sample-section col">
                        <div className="title"><h3>Sample Overview</h3></div>

                            <div className="withscroll">
                                <div className="row3col">

                                    {sample.report_state==="ANALYSIS_IMPOSSIBLE" && <div className="field">
                                        <div className="text">Last Updated:</div>
                                        <div
                                            className="bold mb">{moment(reports[0]?.last_update).format("MMMM D, YYYY")}</div>
                                    </div>}
                                    {sample.report_state==="ANALYSIS_IMPOSSIBLE" || <div className="field">
                                        <div className="text">Date Checked:</div>
                                        <div
                                            className="bold mb">{reports.length > 0 && reports[0]?.reported_at ? moment(reports[0]?.reported_at).format("MMMM D, YYYY") : 'In progress'}</div>
                                    </div>}
                                    <div className="field">
                                        <div className="text">Sample Type:</div>
                                        <div className="bold mb">{getUserFacingSampleType(sample.sample_type)}</div>
                                    </div>
                                </div>
                                <div className="row3col">
                                    {sample.sample_type === "SUBSTANCE" &&  sample.is_quantified > 0 &&
                                        <div className="field">
                                            <div className="text">Sample Weight:</div>
                                            <div className="bold mb">
                                                10.00 mg (10% of a point)
                                            </div>
                                        </div>
                                    }
                                    <div className="field">
                                        <div className="text">Expected Drug(s):</div>
                                        <div
                                            className="bold mb">{sample.expected_drugs.map((ed: any, index: number) => {
                                            return (<div key={index}>{ed}</div>)
                                        })}</div>
                                    </div>
                                </div>
                                <div className="row3col">
                                    {reports.length > 0 && sample?.is_liquid &&
                                        <div className="field">
                                            <div className="text">Sample State:</div>
                                            <div className="bold mb">Liquid</div>
                                        </div>

                                    }
                                    {reports.filter((report_item: any) => report_item.technology === 'TEST_STRIP').length > 0 &&
                                        <div className="field">
                                            <div className="text">Test Strip Result(s):</div>
                                            {reports?.map((r: any) => {
                                                if (r.technology === 'TEST_STRIP')
                                                    return <div
                                                        className="bold ">{r.strip_type}: {ucfirst(r.strip_result)}</div>
                                                return <></>
                                            })}
                                        </div>}
                                </div>

                            </div>

                    </div>

                </div>
                {sample.not_analyzed &&
                    <div>
                        <div className='sample-view-content'>
                            <div className="sample-section col">
                                <div className="title"><h3>Results</h3></div>
                                <div className="field">
                                    <div className="text">This sample could not be analyzed because:</div>
                                    <div className="bold mb">
                                        {sample.not_analyzed}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                }

                {reports && !sample.not_analyzed &&
                    <>
                        {reports?.map((r: any) => {
                            if (r.technology === 'TEST_STRIP')
                                            return <></>
                                        else
                                            return <ViewTechnology technology={getTechnology(r.technology) ?? ""}
                                                                   notes={r.notes} performsCollection={true}
                                                                   isDraft={false}
                                                                   drugs={r.drugs_found} isLiquid={r.is_liquid}
                                                                   weight={r.weight * 1000}/>

                                    })}

                                </>

                            }

                            <div className="sample-view-content">
                                <Accordion allowMultipleExpanded={false} allowZeroExpanded>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Collection Data
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>

                                            <div className="withscroll">
                                                <div className="field">
                                                    <div className="text">Sample is collected from:</div>
                                                    <div
                                                        className="bold mb">{getCollectedFrom(sample.collected_from)}</div>
                                                </div>
                                                <div className="field">
                                                    <div className="text">Have you used this drug checking service
                                                        before?
                                                    </div>
                                                    <div
                                                        className="bold mb">{sample.service_used_before !== null ? sample.service_used_before ? 'Yes' : 'No' : '-'}</div>
                                                </div>
                                                <div className="field">
                                                    <div className="text">Have you previously accessed harm reduction
                                                        services, such
                                                        as drug
                                                        checking or supervised consumption?
                                                    </div>
                                                    <div
                                                        className="bold mb">{sample.previously_accessed !== null ? sample.previously_accessed ? 'Yes' : 'No' : '-'}</div>
                                                </div>
                                                <div className="field">
                                                    <div className="text">Sample type:</div>
                                                    <div
                                                        className="bold mb">{getUserFacingSampleType(sample.sample_type)}</div>
                                                </div>
                                                {sample.sample_type !== "SUBSTANCE" &&
                                                    <div className="field">
                                                        <div className="text">Has this equipment been reused?</div>
                                                        <div
                                                            className="bold mb">{sample.paraphernalia_reuse !== null ? getYesNoDontKnowItem(sample.paraphernalia_reuse)?.text : '-'}</div>
                                                    </div>
                                                }
                                                <div className="field">
                                                    <div className="text">Sample ID:</div>
                                                    <div className="bold mb">{sample.sample_id}</div>
                                                </div>
                                                {sample.collected_from === "USER" && sample.sample_type === "SUBSTANCE" &&
                                                    <div className="field">
                                                        <div className="text">Did you use this drug prior to submitting
                                                            this
                                                            sample?
                                                        </div>
                                                        <div
                                                            className="bold mb">{sample.already_used ? removeUnderline(sample.already_used) : '-'}</div>
                                                    </div>
                                                }
                                                <div className="field">
                                                    <div className="text">What was the sample purchased as?</div>
                                                    <div
                                                        className="bold mb">{sample.expected_drugs && sample.expected_drugs.map((ed: any, index: number) => {
                                                        return <div key={index}>{ed}</div>
                                                    })}</div>
                                                </div>

                                                <div className="field">
                                                    <div className="text">Was this sample associated with an overdose or
                                                        other
                                                        unpleasant or
                                                        abnormal effects?
                                                    </div>
                                                    <div
                                                        className="bold mb">{(sample.sample_effect && sample.sample_effect.length > 0) ? sample.sample_effect.map((ed: any, index: number) => {
                                                        return <div key={index}>{ed}</div>
                                                    }) : '-'}</div>
                                                </div>
                                                {sample.collected_from === "USER" &&
                                                    <div className="field">
                                                        <div className="text">Why are you checking this sample?</div>
                                                        <div
                                                            className="bold mb">{(sample.checking_reasons && sample.checking_reasons.length > 0) ? sample.checking_reasons.map((ed: any, index: number) => {
                                                            return <div key={index}>{ed}</div>
                                                        }) : '-'}</div>
                                                    </div>
                                                }
                                                {sample.collected_from === "USER" &&
                                                    <div className="field">
                                                        <div className="text">Are you planning to wait for your drug
                                                            checking
                                                            results before
                                                            using this drug, using it again, or selling it?
                                                        </div>
                                                        <div
                                                            className="bold mb">{sample.wait_for_results ? removeUnderline(sample.wait_for_results) : '-'}</div>
                                                    </div>
                                                }
                                                <div className="field">
                                                    <div className="text">Sample colour:</div>
                                                    <div
                                                        className="bold mb">{(sample.colour && sample.colour.length > 0) ? sample.colour.map((ed: any, index: number) => {
                                                        return <div key={index}>{ed}</div>
                                                    }) : '-'}</div>
                                                </div>
                                                <div className="field">
                                                    <div className="text">Sample texture:</div>
                                                    <div
                                                        className="bold mb">{(sample.texture && sample.texture.length > 0) ? sample.texture.map((ed: any, index: number) => {
                                                        return <div key={index}>{ed}</div>
                                                    }) : '-'}</div>
                                                </div>
                                                <div className="field">
                                                    <div className="text">How do you wish to receive your results?</div>
                                                    <div
                                                        className="bold mb">{getShareResult(sample.results_provision)}</div>
                                                </div>

                                            </div>

                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>

                            </div>


                        </div>
                        <div className='result-page-content mt-2'>
                            <div className="sample-view-content p-0 m-0">
                                <TermsOfService/>
                            </div>
                        </div>


                        <div style={{
                            justifyContent: "center",
                            display: "flex",
                            justifyItems: "center",
                            marginTop: "130px",
                        }}>
                            <Link to={"/service-users"}
                                  className="btn btn2 access-another-btn">Access Another Result
                            </Link>
                        </div>
                    </UserServicePage>
                    );
                }

                export default UserServiceResultView;
